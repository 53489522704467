import * as Actions from '../constants';

const initialState = {
  jwtToken: sessionStorage.getItem('jwtToken'),
  name: sessionStorage.getItem('name'),
};
const reducers = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_JWT: {
      sessionStorage.setItem('jwtToken', action.payload);
      return { ...state, jwtToken: action.payload }
    }
    case Actions.SET_NAME: {
      sessionStorage.setItem('name', action.payload);
      return { ...state, name: action.payload }
    }
    default: {
      return state;
    }
  }
}
export default reducers;