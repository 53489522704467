/*
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom'
import { store } from './redux/store';
import { Provider } from "react-redux";
import Login from './pages/Login';
import Dashboard from './pages/Dashboard'
import Kgpians from './pages/Kgpians';
import Settings from './pages/Settings';
import EditAlumni from './pages/EditAlumni';
import EmailLog from "./pages/EmailLogs";
import EmailDetails from './pages/EmailDetails';



function App() {
  return (
    <Provider store={store} >
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route path="/kgpians" element={<Kgpians />} />
        <Route path="/email-logs" element={<EmailLog />} />
        <Route path="/email-details/:dispatchId" element={<EmailDetails />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/editalumni/:almid" element={<EditAlumni />} />
      </Routes>
    </Provider>
  );
}

export default App;

*/
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom'
import { store } from './redux/store';
import { Provider } from "react-redux";
import Login from './pages/Login';
import Dashboard from './pages/Dashboard.js'
import Kgpians from './pages/Kgpians';
import Settings from './pages/Settings';
import EditAlumni from './pages/EditAlumni';
import EmailLog from "./pages/EmailLogs";
import EmailDetails from './pages/EmailDetails';
import BirthdayList from './pages/BirthdayList';



function App() {
  return (
    <Provider store={store} >
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route path="/kgpians" element={<Kgpians />} />
        <Route path="/email-logs" element={<EmailLog />} />
        <Route path="/email-details/:dispatchId" element={<EmailDetails />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/editalumni/:almid" element={<EditAlumni />} />
        <Route path="/birthdaylist" element={<BirthdayList />} />
      </Routes>
    </Provider>
  );
}

export default App;
