import React, { Component } from 'react'

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { PeopleAltOutlined } from '@mui/icons-material'
import blueGrey, { grey } from '@mui/material/colors'
import { Link } from '@mui/material';




export class Modules extends Component {
    render() {
        return (
            <Card sx={{ minWidth: 250 }}>
                <CardContent>
                    <Link href='#' style={{ textDecoration: 'none' }}>
                        <Grid container direction='column'>
                            <Grid item display='flex' justifyContent='center'>
                                <PeopleAltOutlined sx={{ fontSize: 80 }} color="primary" />
                            </Grid>
                            <Grid item display='flex' justifyContent='center'>
                                <Typography variant='h6' color={grey[500]}>KGPians</Typography>
                            </Grid>
                        </Grid>
                    </Link>
                </CardContent>
            </Card>
        )
    }
}

export default Modules