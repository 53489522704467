import React, { Component } from 'react'

export class Halls extends Component {
  render() {
    return (
      <div>Halls</div>
    )
  }
}

export default Halls