import React, { Component } from 'react'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCardIcon from '@mui/icons-material/AddCard';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


import { Divider, Grid, Stack, Typography, Paper, TextField, IconButton, Menu, MenuItem, Button, Alert, Pagination, TablePagination } from '@mui/material';
import FilterItem from './FilterItem';


export class Filters extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // dashboardItems: [],
            filters: {},
            filterFields: [],
            appliedFilters: [],
            filtersApplied: false,
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.dashboardItems !== nextProps.dashboardItems) {
            return true;
        }

        if (this.props.filtersApplied !== nextProps.filtersApplied) {
            return true;
        }

        if (this.props.clearFilter !== nextProps.clearFilter) {
            return true;
        }

        if (this.state !== nextState) {
            return true;
        }
        return false;
    }

    handleFilterFieldChange = (index, field) => {
        var x = this.props.dashboardItems.find(d => d.name === field)
        var appFilters = this.state.appliedFilters;
        appFilters[index].filterOptions = x.data;
        appFilters[index].selectedField = field;
        if (index < appFilters.length - 1) {
            appFilters.splice(index + 1);
        }
        this.setState({ appliedFilters: appFilters });
    }

    handleFieldOptionChange = (index, value) => {
        // console.log('field option changed, index',index, value);
        var appFilters = this.state.appliedFilters;
        appFilters[index].selectedOptions = value;
        if (index < appFilters.length - 1) {
            appFilters.splice(index + 1);
        }
        this.setState({ appliedFilters: appFilters });
        if (this.props.onChange !== undefined) {
            // process filters for applying
            let filters = appFilters.map((v, i) => {
                return {
                    field: v.selectedField,
                    options: v.selectedOptions
                }
            })
            // notify changes to the parent.
            this.props.onChange(filters);
        }
    }


    componentDidUpdate(prevProps, prevState) {
        let fields = [];
        if (prevProps.dashboardItems !== this.props.dashboardItems) {
            this.props.dashboardItems.map(di => {
                fields.push({
                    fullname: di.fullname,
                    name: di.name
                });
            })
            this.setState({ filterFields: fields });
        }
        if (prevProps.filtersApplied !== this.props.filtersApplied) {
            // filter applied, change local state to display the add filter button
            // console.log('filters applied from Filters component')
            this.setState({ filtersApplied: this.props.filtersApplied });
        }

        if (prevProps.clearFilter !== this.props.clearFilter) {
            if (this.props.clearFilter === true) {
                this.setState({ appliedFilters: [] });
                if (this.props.onChange !== undefined) {
                    // notify changes to the parent.
                    this.props.onChange([]);
                }
                this.setState({ filtersApplied: true});
            }
        }
    }

    addFilter = () => {
        let appFilters = this.state.appliedFilters;
        // exclude applied filters
        let filterFields = this.state.filterFields;
        appFilters.map(af => {
            let i = filterFields.findIndex(x => x.name === af.selectedField);
            if (i !== -1) {
                filterFields.splice(i, 1);
            }
        })
        // console.log('new applied filters', filterFields);

        appFilters.push(
            {
                index: appFilters.length,
                filters: filterFields,
                selectedField: '',
                filterOptions: [],
                selectedOptions: [],
            }
        )
        this.setState({ appliedFilters: appFilters });
        this.setState({ filtersApplied: false });
    }

    removeFilter = (index) => {
        let appFilters = this.state.appliedFilters;
        appFilters.splice(index);
        this.setState({ appliedFilters: appFilters });
        if (this.props.onChange !== undefined) {
            // process filters for applying
            let filters = appFilters.map((v, i) => {
                return {
                    field: v.selectedField,
                    options: v.selectedOptions
                }
            })
            // notify changes to the parent.
            this.props.onChange(filters);
        }
    }


    render() {
        return (
            <React.Fragment>
                {
                    this.state.appliedFilters.map((af, index) => {
                        return (
                            <FilterItem
                                key={af.index}
                                index={af.index}
                                lastItem={this.state.appliedFilters.length == index + 1 ? true : false}
                                filterFields={af.filters}
                                filterOptions={af.filterOptions}
                                onFieldChange={this.handleFilterFieldChange}
                                onFieldOptionChange={this.handleFieldOptionChange}
                                onRemove={this.removeFilter}
                            />
                        )
                    })
                }
                {
                    this.state.filtersApplied ?
                        <Button variant='contained' onClick={this.addFilter} color='secondary' startIcon={<AddCardIcon />}>
                            Add Filter
                        </Button>
                        : null
                }
            </React.Fragment>
        )
    }
}

export default Filters;