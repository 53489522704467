import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import Moment from "react-moment";

function EmailDetailItem(props) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Recipients</DialogTitle>
        <DialogContent dividers={true}>
          {props.addresses.replace(/,/g, "\r\n")}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item xs={2}>
          <Typography variant="body2">
            <Moment format="DD-MM-YYYY hh:mm a">{props.timestamp}</Moment>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button onClick={handleClickOpen}>View Recipients</Button>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{props.result}</Typography>
        </Grid>

        <Grid item xs={2}>
          {props.status === "error" ? (
            <Typography variant="body2" style={{ color: "red" }}>
              {props.status}
            </Typography>
          ) : (
            <Typography variant="body2" style={{ color: "green" }}>
              {props.status}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default EmailDetailItem;
