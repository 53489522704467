import React, { Component } from 'react'
import Header from '../components/Header'

import { grey } from '@mui/material/colors';
import { Typography, Container, Divider, Grid, Paper, Stack, Button, Box } from '@mui/material';

import { connect } from 'react-redux';
import { setJWT, setName } from '../redux/actions/actions';
import { Navigate } from 'react-router-dom';

import { LocalPostOffice, TextSnippet } from '@mui/icons-material';
import MailSettings from '../components/MailSettings';
import MailContent from '../components/MailContent';

export class Settings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            redirect: false,
            redirectTo: '',
            module: 'EmailSettings',
        }
    }

    modules = {
        EmailSettings: <MailSettings />,
        EmailContent: <MailContent />
    };

    renderModules = (moduleName) => {
        return this.modules[moduleName];
    }

    showEmailSettings = () => {
        this.setState({ module: 'EmailSettings' });
    }
    showEmailContent = () => {
        this.setState({ module: 'EmailContent' });
    }

    checkLogin = () => {
        if (this.props.jwtToken == '') {
            this.setState({ redirectTo: '/' });
            this.setState({ redirect: true });
        }
    }

    componentDidMount() {
        this.checkLogin();
    }

    render() {
        return (
            <div style={{ backgroundColor: grey['200'], minHeight: '100vh' }}>
                {this.state.redirect == true ?
                    <Navigate to={this.state.redirectTo} />
                    : null
                }
                <Header />
                <Container>
                    <Typography variant="h5" color='secondary' marginTop={2}>Settings</Typography>
                    <Divider />
                    <Grid container spacing={2}>
                        <Grid item xs={2} spacing={2}>
                            <Stack direction='row' paddingY={2} justifyContent='space-between'>
                                <Stack direction='column' spacing={2}>
                                    <Button variant="contained" color={this.state.module === 'EmailSettings' ? 'primary' : 'inherit'} startIcon={<LocalPostOffice />} onClick={this.showEmailSettings}>
                                        Email Server
                                    </Button>
                                    <Button variant="contained" color={this.state.module === 'EmailContent' ? 'primary' : 'inherit'} startIcon={<TextSnippet />} onClick={this.showEmailContent}>
                                        Email Content
                                    </Button>
                                </Stack>
                                <Divider orientation='vertical' flexItem />
                            </Stack>
                        </Grid>
                        <Grid item xs={10}>
                            <Box paddingY={2}>
                                <Paper sx={{padding: 4, height: 'auto', paddingY: 5}} flexItem>
                                    {this.renderModules(this.state.module)}
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        name: state.name,
        jwtToken: state.jwtToken,
    }
}

const mapDispatchToProps = {
    setJWT, setName
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);