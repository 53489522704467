import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import Header from "../components/Header";
import { grey } from "@mui/material/colors";
import settings from "../config/Settings";
import axios from "axios";
import { connect } from "react-redux";
import { setJWT, setName } from "../redux/actions/actions";

import SearchIcon from "@mui/icons-material/Search";
import BackspaceIcon from "@mui/icons-material/Backspace";

import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import Email from "../components/Email";

class EmailLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      redirectTo: "",
      emailLogs: [],
      draw: 1,
      recordsTotal: 0,
      recordsFiltered: 0,
      recordsPerPage: 10,
      currentPage: 0,
      searchString: "",
      loadingEmailLogs: true,
    };
  }

  loadEmailLogs = () => {
    this.setState({ emailLogs: [] });
    this.setState({ loadingEmailLogs: true });
    axios
      .post(
        settings.serverUrl + "getEmailLogs",
        {
          draw: this.state.draw,
          start: this.state.currentPage * this.state.recordsPerPage,
          length: this.state.recordsPerPage,
          search: this.state.searchString,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.jwtToken}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          //   console.log('Email logs received');
          //   console.log(res.data.data);
          this.setState({
            recordsTotal: res.data.recordsTotal,
            recordsFiltered: res.data.recordsFiltered,
          });
          this.setState({ emailLogs: res.data.data });
          this.setState({ loadingEmailLogs: false });
        } else {
          console.log(res.data.message);
          if (res.data.status === "error") {
            this.setState({ redirectTo: "/" });
            this.setState({ redirect: true });
          }
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      });
    this.setState((prevState) => {
      return { draw: prevState.draw + 1 };
    });
  };

  clearSearch = () => {
    this.setState({ searchString: "" }, () => {
      this.loadEmailLogs();
    });
  };

  searchWithin = () => {
    this.loadEmailLogs();
  };

  handleSearchTextChange = (event) => {
    //console.log(event.target.value);
    this.setState({ searchString: event.target.value });
  };

  handlePageChange = (event, value) => {
    this.setState({ currentPage: value });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ recordsPerPage: parseInt(event.target.value, 10) }, () => {
      this.loadEmailLogs();
      this.setState({ currentPage: 0 });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.loadEmailLogs();
    }
  }
  componentDidMount() {
    if (this.props.jwtToken === "") {
      this.setState({ redirectTo: "/" });
      this.setState({ redirect: true });
    }
    this.loadEmailLogs();
  }

  render() {
    return (
      <div style={{ backgroundColor: grey["200"], minHeight: "100vh" }}>
        {this.state.redirect === true ? (
          <Navigate to={this.state.redirectTo} />
        ) : null}
        <Header />
        <div>
          <Container style={{ marginTop: 20 }}>
            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={6}>
                <Typography variant="h5" color="secondary" marginTop={2}>
                  Emaiil Logs
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Subject or Date"
                    inputProps={{ "aria-label": "Search Subject or Date" }}
                    onChange={this.handleSearchTextChange}
                    value={this.state.searchString}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={this.clearSearch}
                  >
                    <BackspaceIcon />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                    onClick={this.searchWithin}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Grid>
            </Grid>
            <Divider />
            <Paper style={{ padding: 15, marginTop: 10 }}>
              <Stack spacing={2} style={{ marginBottom: 20 }}>
                <Grid container>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Date</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Subject</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Message</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Status</Typography>
                  </Grid>
                </Grid>
                <Divider />
                {this.state.loadingEmailLogs ? <CircularProgress /> : null}
                {this.state.emailLogs.map((email) => {
                  return (
                    <Email
                      key={email.DISPATCHID}
                      dispatchId={email.DISPATCHID}
                      timestamp={email.TIMESTAMP}
                      total={email.Total}
                      sent={email.SENT}
                      failed={email.FAILED}
                      subject={email.SUBJECT}
                      message={email.MESSAGE}
                    />
                  );
                })}
                <TablePagination
                  component="div"
                  count={this.state.recordsFiltered}
                  page={this.state.currentPage}
                  onPageChange={this.handlePageChange}
                  rowsPerPage={this.state.recordsPerPage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  showFirstButton={true}
                  showLastButton={true}
                />
              </Stack>
            </Paper>
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.name,
    jwtToken: state.jwtToken,
  };
};

const mapDispatchToProps = {
  setJWT,
  setName,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailLog);
