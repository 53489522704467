import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { grey } from "@mui/material/colors";
import axios from "axios";
import settings from "../config/Settings";
import { setJWT, setName } from "../redux/actions/actions";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Header from "../components/Header";
import parser from "html-react-parser";

import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TablePagination,
  Typography,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BackspaceIcon from "@mui/icons-material/Backspace";
import Moment from "react-moment";
import EmailDetailItem from "../components/EmailDetailItem";

function EmailDetails(props) {
  const { dispatchId } = useParams();
  const jwtToken = useSelector((state) => state.jwtToken);
  const [emailLogs, setEmailLogs] = useState([]);
  const [draw, setDraw] = useState(1);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [recordsFiltered, setRecordsFiltered] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [loadingEmailLogs, setLoadingEmailLogs] = useState(true);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [redirectTo, setRedirectTo] = useState("");

  const loadEmailLogs = () => {
    setEmailLogs([]);
    setLoadingEmailLogs(true);
    axios
      .post(
        settings.serverUrl + "getEmailDetails",
        {
          draw: draw,
          start: currentPage * recordsPerPage,
          length: recordsPerPage,
          dispatchid: dispatchId,
          search: searchString,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log("Email Logs: ");
        console.log(res);
        if (res.data.status === "success") {
          //   console.log('Email logs received');
          //   console.log(res.data.data);
          setRecordsTotal(res.data.recordsTotal);
          setRecordsFiltered(res.data.recordsFiltered);
          setEmailLogs(res.data.data);
          setSubject(res.data.subject);
          setMessage(res.data.message);
          setLoadingEmailLogs(false);
        } else {
          console.log(res.data.message);
          if (res.data.status === "error") {
            setRedirectTo("/");
            setRedirect(true);
          }
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      });
      setDraw({draw: draw+1});
  };

  const clearSearch = () => {
    setSearchString("");
  };

  const searchWithin = () => {
    loadEmailLogs();
  };

  const handleSearchTextChange = (event) => {
    setSearchString(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRecordsPerPage(event.target.value, 10);
  };

  useEffect(() => {
    loadEmailLogs();
  }, [recordsPerPage, currentPage]);

  useEffect(() => {
    if (searchString === "") {
      loadEmailLogs();
      setCurrentPage(0);
    }
  }, [searchString]);

  return (
    <div style={{ backgroundColor: grey["200"], minHeight: "100vh" }}>
      {redirect === true ? (
        <Navigate to={redirectTo} />
      ) : null}
      <Header />

      <div>
        <Container style={{ marginTop: 20 }}>
          <Grid container>
            <Grid item xs={2}>
              <Typography variant="subtitle1">DispatchID</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1">{dispatchId}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">Subject</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1">{subject}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">Message</Typography>
            </Grid>
            <Grid item xs={10}>
              <Paper sx={{ p: 2, mb: 2 }}>
                <Typography variant="body1">{parser(message)}</Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={6}>
              <Typography variant="h5" color="secondary" marginTop={2}>
                Emaiil Details
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Subject or Date"
                  inputProps={{ "aria-label": "Search Subject or Date" }}
                  onChange={handleSearchTextChange}
                  value={searchString}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={clearSearch}
                >
                  <BackspaceIcon />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                  onClick={searchWithin}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
          <Divider />
          <Paper style={{ padding: 15, marginTop: 10 }}>
            <Stack spacing={2} style={{ marginBottom: 20 }}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">Date</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">Recipients</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Result</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">Status</Typography>
                </Grid>
              </Grid>
              <Divider />
              {loadingEmailLogs ? <CircularProgress /> : null}
              {emailLogs.map((email) => {
                return (
                  <Fragment>
                    <EmailDetailItem
                      key={email.mailid}
                      timestamp={email.timestamp}
                      addresses={email.TOBCC}
                      result={email.RESULT}
                      status={email.STATUS}
                    />
                  </Fragment>
                );
              })}
              <TablePagination
                component="div"
                count={recordsFiltered}
                page={currentPage}
                onPageChange={handlePageChange}
                rowsPerPage={recordsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton={true}
                showLastButton={true}
              />
            </Stack>
          </Paper>
        </Container>
      </div>
    </div>
  );
}

export default EmailDetails;
