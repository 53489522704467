import { Avatar, Typography, Divider, IconButton, Grid } from '@mui/material';
import { grey, blueGrey } from '@mui/material/colors';
import { Stack } from '@mui/system';
import React, { Component } from 'react'

import { Link } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export class AlumniListItem extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    stringToColor = (string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    stringAvatar = (name) => {
        return {
            sx: {
                bgcolor: this.stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    editAlumni = () => {

    }

    deleteAlumni = () => {

    }





    render() {
        return (
            <Grid container>
                <Grid item xs={10}>
                    <Stack direction="row" spacing={2}>
                        <Avatar {...this.stringAvatar(this.props.name)} />
                        <Stack direction="column">
                            <Stack direction="row" spacing={2}>
                                <Typography variant='subtitle1' color={grey}>{this.props.name}</Typography>
                                {/* '{this.props.degreeYear.substring(2)} */}
                                <Typography variant='subtitle2' color={blueGrey[300]} paddingTop={0.5}>{this.props.degree} </Typography>
                            </Stack>
                            <Typography variant="body1" color={grey[700]}>{this.props.position} - {this.props.company}</Typography>
                            <Stack direction='row' spacing={1}>
                                <Typography variant="body2" color={grey[600]}>{(this.props.email.slice(-1) == ',') ? this.props.email.slice(0, -1) : this.props.email}</Typography>
                                <Divider orientation='vertical' />
                                <Typography variant="body2" color={grey[600]}>Ph: {this.props.mobile}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={2}>
                    <Stack direction="row" justifyContent='end'>
                        <Link to={"/editalumni/" + this.props.almid} title='Edit Alumni'  style={{ textDecoration: 'none', color:'#607d8b', pointerEvents: "none" }}>
                            <EditIcon />
                        </Link>
                        <Link to={"/deletalumni/" + this.props.almid} title='Delete Alumni' style={{ textDecoration: 'none', color:'#607d8b', pointerEvents: "none" }}>
                            <DeleteIcon />
                        </Link>
                    </Stack>
                </Grid>
            </Grid>
        )
    }
}

export default AlumniListItem