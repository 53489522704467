import { Stack, Box, Button, Typography, Grid } from '@mui/material';
import axios from 'axios'
import React, { Component } from 'react'
import settings from '../config/Settings';
import { Navigate } from 'react-router-dom';
import { Buffer } from 'buffer'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { EditorState, convertToRaw, convertFromHTML, ContentState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { connect } from 'react-redux';
import { setJWT } from '../redux/actions/actions';

export class MailHeaderFooter extends Component {
	constructor(props) {
		super(props)

		this.state = {
			data: this.props.data,
			openInfoDialog: false,
			infoMessage: '',
			redirect: false,
			redirectTo: '',
			editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(this.props.data)))
		}
	}


	static getDerivedStateFromProps(nextProps, prevState) {
		if (!prevState.data) {
			const blocksFromHTML = convertFromHTML(nextProps.data);
			const content = ContentState.createFromBlockArray(blocksFromHTML)

			return { data: nextProps.data, editorState: EditorState.createWithContent(content) };
		}

		return null;
	}


	handleFormDataChange = (event) => {
		this.setState({ data: event.target.value });
	}

	updateMailSettings = () => {
		let body_64 = Buffer.from(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))).toString('base64');
		axios.post(settings.serverUrl + "updateConfig", {
			"keyfld": this.props.name,
			"keyvalue": body_64,
			"keytype": 'C'
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.props.jwtToken}`
			}
		}).then(res => {
			if (res.data.status == 'success') {
				this.setState({ infoMessage: `${this.props.label} Updated Successfully` });
				this.setState({ openInfoDialog: true });
			} else {
				console.log(res.data.message);
				if (res.data.status == 'error') {
					this.setState({ redirectTo: '/' });
					this.setState({ redirect: true });
				}
			}
		}).catch(err => {
			console.log("Error:", err);
		})
	}

	handleDialogClose = (event, reason) => {
		if (reason && reason == "backdropClick" && "escapeKeyDown")
			return;
		else {
			this.setState({ openDialog: false });
			this.setState({ openInfoDialog: false });
		}
	};

	onEditorStateChange = (editorState) => {
		if (this.props.onChange !== undefined) {
			this.props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
		}
		this.setState({ editorState: editorState });

	};

	render() {
		return (
			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>{this.props.label}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Stack direction='column' spacing={1}>
						{this.state.redirect == true ?
							<Navigate to={this.state.redirectTo} />
							: null
						}
						<Grid container spacing={2} paddingX={2}>
							{/* <Grid item><Typography variant='h6'>{this.props.label}</Typography></Grid> */}
							<Grid item><Button onClick={this.updateMailSettings} variant='contained'>Update {this.props.label}</Button></Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Editor
									editorState={this.state.editorState}
									wrapperClassName="demo-wrapper"
									editorClassName="demo-editor"
									editorStyle={{ border: '1px solid #bdbdbd', borderRadius: 5, padding: 10, height: 200 }}
									onEditorStateChange={this.onEditorStateChange}
								/>
							</Grid>
						</Grid>
						<Dialog
							open={this.state.openInfoDialog}
							onClose={this.handleDialogClose}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">Information</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									{this.state.infoMessage}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleDialogClose}>
									OK
								</Button>
							</DialogActions>
						</Dialog>
					</Stack>
				</AccordionDetails>
			</Accordion>


		)
	}
}

const mapStateToProps = (state) => {
	return {
		jwtToken: state.jwtToken,
	}
}

const mapDispatchToProps = {
	setJWT
}

export default connect(mapStateToProps, mapDispatchToProps)(MailHeaderFooter)