import * as Actions from '../constants';


  const setJWT = value => {
    return {
      type: Actions.SET_JWT,
      payload: value
    }
  }
  const setName = value => {
    return {
      type: Actions.SET_NAME,
      payload: value
    }
  }
  
  

export {
  setJWT,
  setName
};