import React, { Component } from 'react'
import settings from '../config/Settings';
import axios from 'axios';
import { connect } from 'react-redux';
import { setJWT, setName } from '../redux/actions/actions';
import { Navigate } from 'react-router-dom';
import { Stack, TextField, Button, Typography, Divider } from '@mui/material';




export class AlumniDetails extends Component {

	constructor(props) {
		super(props)

		this.state = {
			redirect: false,
            redirectTo: '',
			alumniDetails: {}
		}
	}


	getAlumniDetails = () => {
		axios.post(settings.serverUrl + "getAlumniDetails", {
			almid: this.props.almid
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.props.jwtToken}`
			}
		}).then(res => {
			if (res.data.status == 'success') {
				this.setState({alumniDetails: res.data.data});
				console.log(res.data.data);
			} else {
				console.log(res.data.message);
				if (res.data.status == 'error') {
					this.setState({ redirectTo: '/' });
					this.setState({ redirect: true });
				}
			}
		}).catch(err => {
			console.log("Error:", err);
		})

	}


	handleFormDataChange = (event) => {
		console.log(this.state.alumniDetails.F_NAME)
		this.setState({...this.state.alumniDetails, F_NAME: event.target.value});
	}

	componentDidMount() {
        if (this.props.jwtToken == '') {
            this.setState({ redirectTo: '/' });
            this.setState({ redirect: true });
        }
        
        this.getAlumniDetails();
    }

	render() {
		return (
			<Stack direction='column' spacing={2}>
				{this.state.redirect == true ?
                    <Navigate to={this.state.redirectTo} />
                    : null
                }
				<Typography variant='h6'>Alumni Details</Typography><Divider/>
				<Stack direction='row' spacing={2}>
					<TextField
						variant="filled"
						name='F_NAME'
						fullWidth label='First Name'
						value={this.state.alumniDetails.F_NAME}
						onChange={this.handleFormDataChange}
					></TextField>					
				</Stack>
				<Stack direction='row' spacing={2}>
					<TextField
						variant="filled"
						name='L_NAME'
						fullWidth label='Last Name'
						value={this.state.alumniDetails.L_NAME}
						onChange={this.handleFormDataChange}
					></TextField>					
				</Stack>				
			</Stack>
		)
	}
}



const mapStateToProps = (state) => {
	return {
		name: state.name,
		jwtToken: state.jwtToken,
	}
}

const mapDispatchToProps = {
	setJWT, setName
}

export default connect(mapStateToProps, mapDispatchToProps)(AlumniDetails)