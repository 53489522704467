import React, { Component } from 'react'

export class Awards extends Component {
  render() {
    return (
      <div>Awards</div>
    )
  }
}

export default Awards