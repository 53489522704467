import React, { Component } from 'react'
import { grey } from '@mui/material/colors';
import { InputBase, Divider, Grid, Stack, Typography, Paper, TextField, IconButton, Menu, MenuItem, Button, Alert, Pagination, TablePagination } from '@mui/material';
import { Container } from '@mui/system';
import Header from '../components/Header';
import settings from '../config/Settings';
import axios from 'axios';
import { connect } from 'react-redux';
import { setJWT, setName } from '../redux/actions/actions';
import AlumniListItem from '../components/AlumniListItem';
import { Navigate } from 'react-router-dom';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import BackspaceIcon from '@mui/icons-material/Backspace';
import EmailIcon from '@mui/icons-material/Email';
import InfoIcon from '@mui/icons-material/Info';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Buffer } from 'buffer';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MailHeaderFooter from '../components/MailHeaderFooter';
import Filters from '../components/Filters';
import EmailAttachment from '../components/EmailAttachment';
import { FilterList } from '@mui/icons-material';

export class Kgpians extends Component {
    constructor(props) {
        super(props)

        this.state = {
            anchorEl: null,
            openDialog: false,
            openInfoDialog: false,
            infoMessage: '',
            openBackdrop: false,
            redirect: false,
            redirectTo: '',
            filters: [],
            filtersApplied: false,
            clearFilter: false,
            alumniList: [],
            // filteredAlumniList: [],
            dashboardItems: [],
            fewEmails: '',

            isValid: true,
            message: '',

            contentHeader: '',
            contentFooter: '',
            includeHeader: true,
            includeFooter: true,
            replyTo: settings.replyTo,
            subject: '',
            from: settings.fromField,
            // body: '',
            editorState: EditorState.createEmpty(),
            searchString: '',
            draw: 1,
            recordsTotal: 0,
            recordsFiltered: 0,
            recordsPerPage: 10,
            currentPage: 0,
            attachments: '',
            loadingDashboardItems: true,
            loadingAlumniList: true,
            addHeaderFooter: true
        }

        this.headerRef = React.createRef();
        this.footerRef = React.createRef();
    }


    getEmailContentSettings = () => {
        axios.post(settings.serverUrl + "getConfigByCatg", {
            cfgcatg: 'C'
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.props.jwtToken}`
            }
        }).then(res => {
            if (res.data.status == 'success') {
                //console.log('Received Email Content Settings');

                res.data.data.map(s => {
                    this.setState({ [s.KEYF]: atob(s.KYVALUE) });
                })
            } else {
                console.log(res.data.message);
                if (res.data.status == 'error') {
                    this.setState({ redirectTo: '/' });
                    this.setState({ redirect: true });
                }
            }
        }).catch(err => {
            console.log("Error:", err);
        })
    }

    onEditorStateChange = (editorState) => {
        this.setState({ editorState: editorState });
    };

    handleFormDataChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    getFilterList = () => {
        var filterlist = [];
        var filters = this.state.filters;
        filters.map(f => {
            var subfilterlist = '';
            f.options.map(o => {
                subfilterlist += o.NAME + ',';
            })
            if (subfilterlist.length != 0) {
                filterlist.push(
                    {
                        fld: f.field,
                        value: subfilterlist.slice(0, -1)
                    }
                )
            }
        })
        return filterlist;
    }


    loadAlumniList = (filtered = false) => {
        //this.setState({ searchString: '' });
        var filterlist = [];
        if (filtered == true) {
            filterlist = this.getFilterList();
        }
        this.setState({alumniList: []});
        this.setState({recordsFiltered: 0, recordsTotal: 0});
        this.setState({ loadingAlumniList: true });
        axios.post(settings.serverUrl + "getAlumniList", {
            filters: filterlist,
            draw: this.state.draw,
            start: this.state.currentPage * this.state.recordsPerPage,
            length: this.state.recordsPerPage,
            search: this.state.searchString
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.props.jwtToken}`
            }
        }).then(res => {
            if (res.data.status == 'success') {
                // console.log('Filtered Alumni List received');
                this.setState({ recordsTotal: res.data.recordsTotal, recordsFiltered: res.data.recordsFiltered })
                this.setState({ alumniList: res.data.data });
                this.setState({ loadingAlumniList: false });

            } else {
                console.log(res.data.message);
                if (res.data.status == 'error') {
                    this.setState({ redirectTo: '/' });
                    this.setState({ redirect: true });
                }
            }
        }).catch(err => {
            console.log("Error:", err);
        })
        this.setState(prevState => { return { draw: prevState.draw + 1 } });
    }

    loadDashboardItems = (filtered = false) => {
        var filterlist = [];
              

        if (filtered == true) {
            filterlist = this.getFilterList();
        }
        //console.log(filterlist);

        axios.post(settings.serverUrl + "getDashBoardContents", {
            filters: filterlist
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.props.jwtToken}`
            }
        }).then(res => {
            if (res.data.status == 'success') {
                // console.log('Dashboard Items are received');
                this.setState({ dashboardItems: res.data.data }, () => {
                    console.log('Dashboard items are loaded');
                    this.setState({ loadingDashboardItems: false });
                    this.setState({ filtersApplied: true });
                });
            } else {
                console.log(res.data.message);
                if (res.data.status == 'error') {
                    this.setState({ redirectTo: '/' });
                    this.setState({ redirect: true });
                }
            }
        }).catch(err => {
            console.log("Error:", err);
        })
    }

    // open dropdown menu
    // handleClick = (event) => {
    //     //console.log('handleClick');
    //     this.setState({ anchorEl: event.currentTarget })
    // };
    // close dropdown menu
    // handleClose = () => {
    //     //console.log('handleClose');
    //     this.setState({ anchorEl: null });
    // };


    handleDialogClose = (event, reason) => {
        if (reason && reason == "backdropClick" && "escapeKeyDown")
            return;
        else {
            this.setState({ openDialog: false });
            this.setState({ openInfoDialog: false });
        }
    };

    showAddAlumniDialog = () => {

    } 

    showEmailDialog = () => {
        if(this.state.addHeaderFooter == true){
            var contentBlock = htmlToDraft(this.state.contentHeader + '<br><br>'+this.state.contentFooter);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({editorState: editorState});
            this.setState({addHeaderFooter: false});
        }       

        // get first few email ids
        let fewEmails = '';
        
        for(var i=0; i<this.state.alumniList.length && i<10; i++){
            if(this.state.alumniList[i].EMAILS != null && this.state.alumniList[i].EMAILS != '')
                fewEmails += this.state.alumniList[i].EMAILS+', ';
        }
        fewEmails += '...';
        this.setState({fewEmails: fewEmails});
        console.log('Emails: '+ fewEmails);

        this.setState({ anchorEl: null });
        this.setState({ openDialog: true });
    }

    closeBackdrop = () => {
        this.setState({ openBackdrop: false });
    }


    submitMail = () => {
        this.setState({ message: "", isValid: true });
        if (this.state.replyTo.trim() == '') {
            this.setState({ message: "ReplyTo is required", isValid: false });
            return;
        }
        if (this.state.subject.trim() == '') {
            this.setState({ message: "Subject is required", isValid: false });
            return;
        }
        // if (this.state.body.trim() == '') {
        //     this.setState({ message: "Body is required", isValid: false });
        //     return;
        // }
        this.setState({ openDialog: false });


        // // get all email ids
        // let emails = '';
        // this.state.alumniList.map(alumni => {
        //     emails += alumni.EMAILID_1 + ',';
        // })
        // //console.log(emails);
        // emails = emails.slice(0, -1);

        let sender_64 = Buffer.from(this.state.from).toString('base64');
        let replyto_64 = Buffer.from(this.state.replyTo).toString('base64');
        // let emails_64 = Buffer.from(emails).toString('base64');
        let subject_64 = Buffer.from(this.state.subject).toString('base64');
        let attach_64 = Buffer.from(this.state.attachments).toString('base64');

        // console.log(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));

        this.setState({ openBackdrop: true });
        let body = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));

        let body_64 = Buffer.from(body).toString('base64');

        var filterList = [];
        filterList = this.getFilterList();

        axios.post(settings.serverUrl + "submitMail", {
            sender: sender_64,
            replyto: replyto_64, 
            filters: filterList,
            search: this.state.searchString,
            subject: subject_64,
            msghtml: body_64,
            attach: attach_64
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.props.jwtToken}`
            }
        }).then(res => {
            this.setState({ openBackdrop: false });
            console.log(res.data.message);
            if (res.data.status == 'success') {
                this.setState({ infoMessage: 'Mails are queued for sending, please see the Mail Section for details' });
                this.setState({addHeaderFooter: true});
                this.setState({ openInfoDialog: true });
            } else {
                if (res.data.status == 'error') {
                    this.setState({ openBackdrop: false });
                    this.setState({ infoMessage: res.data.status });
                    this.setState({ openInfoDialog: true });
                }
            }
        }).catch(err => {
            console.log("Error:", err);
        })
    }


    filterChanged = (filters) => {
        this.setState({ filters: filters }, () => {
            if (filters.length == 0) {
                this.loadAlumniList(false);
                this.setState({ filtersApplied: true });
            } else {
                this.setState({ filtersApplied: false });
                this.setState({ clearFilter: false });
            }
        });
    }

    applyFilter = () => {
        //console.log(this.state.filters);
        this.setState({ currentPage: 0 }, () => {
            this.loadDashboardItems(true);
            this.loadAlumniList(true);
            this.setState({ filtersApplied: true });
        });
    }

    removeAllFilters = () => {
        //console.log(this.state.filters);
        this.setState({ clearFilter: true });
        this.setState({ currentPage: 0 }, () => {
            this.loadDashboardItems(false);
            this.loadAlumniList(false);
            this.setState({ filtersApplied: true });
        });
    }

    handleSearchTextChange = (event) => {
        //console.log(event.target.value);
        this.setState({ searchString: event.target.value });
    }

    searchWithin = () => {
        this.loadAlumniList(true);
    }

    handleHeaderChange = (value) => {
        this.setState({ contentHeader: value });
    }
    handleFooterChange = (value) => {
        this.setState({ contentFooter: value });
    }
    handleIncludeHeader = (event) => {
        this.setState({ includeHeader: event.target.checked })
    }
    handleIncludeFooter = (event) => {
        this.setState({ includeFooter: event.target.checked })
    }

    handlePageChange = (event, value) => {
        //console.log(value);
        this.setState({ currentPage: value });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ recordsPerPage: parseInt(event.target.value, 10) }, () => {
            this.loadAlumniList(true);
            this.setState({ currentPage: 0 });
        });

    };

    clearSearch = () => {
        this.setState({ searchString: '' }, () => {
            this.loadAlumniList(true);
        });
    }

    attachmentChanged = (attachments) => {
        this.setState({ attachments: attachments });
        console.log('attachments', attachments);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentPage !== this.state.currentPage) {
            this.loadAlumniList(true);
        }
    }

    componentDidMount() {
        if (this.props.jwtToken == '') {
            this.setState({ redirectTo: '/' });
            this.setState({ redirect: true });
        }
        // set editor state
        
        // Load dashboard items
        this.loadDashboardItems();
        // load alumni list
        this.loadAlumniList();
        this.getEmailContentSettings();
    }



    render() {
        let open = Boolean(this.state.anchorEl);
        const { editorState } = this.state;
        return (
            <div style={{ backgroundColor: grey['200'], minHeight: '100vh' }}>
                {this.state.redirect == true ?
                    <Navigate to={this.state.redirectTo} />
                    : null
                }
                <Header />
                <div  >
                    <Container>
                        <Typography variant="h5" color='secondary' marginTop={2}>KGPians</Typography>
                        <Divider />
                        <Grid container marginY={2} direction='row' spacing={2}>
                            <Grid item xs={8}>
                                <Paper style={{ padding: 15 }}>
                                    <Stack direction="row" spacing={2} style={{ marginBottom: 20 }}>
                                        <Paper
                                            component="form"
                                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                                        >
                                            <InputBase
                                                sx={{ ml: 1, flex: 1 }}
                                                placeholder="Search Name and Email"
                                                inputProps={{ 'aria-label': 'Search Name and Email' }}
                                                onChange={this.handleSearchTextChange}
                                                value={this.state.searchString}
                                            />
                                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={this.clearSearch}>
                                                <BackspaceIcon />
                                            </IconButton>
                                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                            <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={this.searchWithin}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                        {/* <IconButton
                                            onClick={this.handleClick}
                                        // aria-controls={this.open ? 'account-menu' : undefined}
                                        // aria-haspopup="true"
                                        // aria-expanded={this.open ? 'true' : undefined}
                                        >
                                            <MoreVertIcon />
                                        </IconButton> */}
                                        {/* <Menu
                                            id="basic-menu"
                                            anchorEl={this.state.anchorEl}
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={this.handleClose}
                                            keepMounted
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem key={1} onClick={this.showEmailDialog}>Send Email</MenuItem>
                                        </Menu> */}
                                        <IconButton color='primary' title='Send Email' onClick={this.showEmailDialog} >
                                            <EmailIcon />
                                        </IconButton>
                                        <IconButton color='secondary' title='Add New Alumni' onClick={this.showAddAlumniDialog} >
                                            <AddCircleIcon />
                                        </IconButton>
                                    </Stack>
                                    <Stack direction='row' spacing={0.5} marginY={2}>
                                        <Typography variant="subtitle2">{this.state.recordsFiltered}</Typography>
                                        <Typography variant="subtitle2" color={grey[700]}>KGPians</Typography>
                                    </Stack>
                                    <Divider />
                                    {
                                        this.state.loadingAlumniList ?
                                            <CircularProgress />
                                            : null

                                    }
                                    <Stack spacing={3} marginTop={2}>
                                        {
                                            this.state.alumniList.map(alumni => {
                                                return (
                                                    <AlumniListItem
                                                        key={alumni.ALMID}
                                                        almid={alumni.ALMID}
                                                        name={alumni.F_NAME + " " + alumni.L_NAME}
                                                        degree={alumni.DEGREE}
                                                        degreeYear={alumni.DEG_YEAR}
                                                        position={alumni.POSITION}
                                                        company={alumni.COMPANY}
                                                        email={alumni.EMAILS !== null ? alumni.EMAILS.trim() : ''}
                                                        mobile={alumni.RES_MOBILE}
                                                    />
                                                )

                                            })
                                        }
                                        <Divider />
                                        <TablePagination
                                            component="div"
                                            count={this.state.recordsFiltered}
                                            page={this.state.currentPage}
                                            onPageChange={this.handlePageChange}
                                            rowsPerPage={this.state.recordsPerPage}
                                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                                            showFirstButton={true}
                                            showLastButton={true}
                                        />

                                    </Stack>
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack direction='column' spacing={2}>
                                    <Stack direction='row' justifyContent={'space-between'}>
                                        <Typography variation='subtitle1'>Filters</Typography>
                                        <Button variant='contained' onClick={this.applyFilter}>Apply Filter</Button>
                                        <Button variant='contained' onClick={this.removeAllFilters}>Clear All Filters</Button>
                                    </Stack>
                                    <Divider />
                                    {
                                        this.state.loadingDashboardItems ?
                                            <CircularProgress />
                                            : null

                                    }
                                    <Filters
                                        dashboardItems={this.state.dashboardItems}
                                        filtersApplied={this.state.filtersApplied}
                                        onChange={this.filterChanged}
                                        clearFilter={this.state.clearFilter}
                                    />

                                </Stack>
                            </Grid>
                        </Grid>


                    </Container>
                </div>



                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleDialogClose}
                    fullWidth={true}
                    maxWidth='sx'
                    scroll={'paper'}
                    PaperProps={{
                        sx: {
                            height: '90%'
                        }
                    }}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">
                        Send Custom Email to Selected People                        
                            <Tooltip title={this.state.fewEmails}>
                                <IconButton color="info">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>                        
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <Grid container direction='column' spacing={1}>
                            <Grid item>
                                {
                                    !this.state.isValid ?
                                        <Alert severity="warning">{this.state.message}</Alert>
                                        : null
                                }
                            </Grid>
                            <Grid item>
                                <Stack spacing={2}>
                                    <Stack direction='row' spacing={5}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth label='From'
                                            name='from'
                                            value={this.state.from}
                                            size='small'
                                            onChange={this.handleFormDataChange}
                                        ></TextField>
                                        <TextField
                                            variant="outlined"
                                            fullWidth label='Reply To'
                                            name='replyTo'
                                            value={this.state.replyTo}
                                            size='small'
                                            onChange={this.handleFormDataChange}
                                        ></TextField>
                                    </Stack>
                                    <TextField
                                        variant="outlined"
                                        fullWidth label='Subject'
                                        name='subject'
                                        value={this.state.subject}
                                        size='small'
                                        onChange={this.handleFormDataChange}
                                    ></TextField>
                                </Stack>

                            </Grid>
                            {/* <Grid item>
                                <Stack direction='row' spacing={2}>
                                    <MailHeaderFooter
                                        name='contentHeader'
                                        label='Email Header'
                                        // ref={this.headerRef}
                                        data={this.state.contentHeader}
                                        onChange={this.handleHeaderChange}
                                    />

                                    <MailHeaderFooter
                                        name='contentFooter'
                                        label='Email Footer'
                                        // ref={this.footerRef}
                                        data={this.state.contentFooter}
                                        onChange={this.handleFooterChange}
                                    />
                                </Stack>
                            </Grid> */}
                            {/* <Grid item>
                                <Grid container>
                                    <Grid item xs={6} paddingX={2}>
                                        <FormControlLabel control={<Checkbox checked={this.state.includeHeader} onChange={this.handleIncludeHeader} />} label="Include Header" />
                                    </Grid>
                                    <Grid item xs={6} paddingX={2}>
                                        <FormControlLabel control={<Checkbox checked={this.state.includeFooter} onChange={this.handleIncludeFooter} />} label="Include Footer" />
                                    </Grid>
                                </Grid>
                            </Grid> */}
                            <Grid item paddingX={2}>
                                <EmailAttachment attachmentChanged={this.attachmentChanged} />
                            </Grid>

                            <Grid item>
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    editorStyle={{ border: '1px solid #bdbdbd', borderRadius: 5, padding: 10, minHeight: '300px' }}
                                    onEditorStateChange={this.onEditorStateChange}
                                />

                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose}>Cancel</Button>
                        <Button onClick={this.submitMail}>Submit</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openInfoDialog}
                    onClose={this.handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Information</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.infoMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>


                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.openBackdrop}
                // onClick={this.closeBackdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        name: state.name,
        jwtToken: state.jwtToken,
    }
}

const mapDispatchToProps = {
    setJWT, setName
}

export default connect(mapStateToProps, mapDispatchToProps)(Kgpians);