import React, { Component } from 'react'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



export class ConfirmationDialog extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        open: false
      }
    }


    handleYes = () => {
        this.setState({open: false});
        if(this.props.onClick !== undefined){
            this.props.onClick('YES');
        }
    }

    handleNo = () => {
        this.setState({open: false});
        if(this.props.onClick !== undefined){
            this.props.onClick('NO');
        }
    }

    handleClose = (event, reason) => {
        if (reason && reason == "backdropClick" && "escapeKeyDown")
            return;
        else {
            this.setState({open: false});
            if(this.props.onClick !== undefined){
                this.props.onClick('NO');
            }
        }
        
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.open !== this.props.open){
            this.setState({open: this.props.open});
        }
    }

    render() {
        const {open=false, title = 'Informatioon', content = '...' } = this.props;
        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleNo}>NO</Button>
                    <Button onClick={this.handleYes} autoFocus>
                        YES
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ConfirmationDialog