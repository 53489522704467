import { Stack, TextField, Button, Divider } from '@mui/material';
import axios from 'axios'
import React, { Component } from 'react'
import settings from '../config/Settings';
import { Navigate } from 'react-router-dom';

import { connect } from 'react-redux';
import { setJWT, setName } from '../redux/actions/actions';
import MailHeaderFooter from './MailHeaderFooter';

export class MailContent extends Component {
	constructor(props) {
		super(props)

		this.state = {
			redirect: false,
			redirectTo: '',
			contentHeader: '',
			contentFooter: '',
		}
	}

	getEmailContentSettings = () => {
		axios.post(settings.serverUrl + "getConfigByCatg", {
			cfgcatg: 'C'
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.props.jwtToken}`
			}
		}).then(res => {
			if (res.data.status == 'success') {
				console.log('Received Email Content Settings');

				res.data.data.map(s => {
					this.setState({ [s.KEYF]: atob(s.KYVALUE) });
				})
			} else {
				console.log(res.data.message);
				if (res.data.status == 'error') {
					this.setState({ redirectTo: '/' });
					this.setState({ redirect: true });
				}
			}
		}).catch(err => {
			console.log("Error:", err);
		})
	}

	componentDidMount() {
		this.getEmailContentSettings();
	}

	render() {
		return (
			<Stack direction='column' spacing={2}>
				{this.state.redirect == true ?
					<Navigate to={this.state.redirectTo} />
					: null
				}
				
				<MailHeaderFooter
					name='contentHeader'
					label='Email Header'
					data={this.state.contentHeader}
				/>
				<Divider />
				<MailHeaderFooter
					name='contentFooter'
					label='Email Footer'
					data={this.state.contentFooter}
				/>				
			</Stack>
		)
	}
}


const mapStateToProps = (state) => {
	return {
		name: state.name,
		jwtToken: state.jwtToken,
	}
}

const mapDispatchToProps = {
	setJWT, setName
}

export default connect(mapStateToProps, mapDispatchToProps)(MailContent);