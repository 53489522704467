import React, { Component } from 'react'
import { grey } from '@mui/material/colors';
import { InputBase, Divider, Grid, Stack, Typography, Paper, TextField, IconButton, Menu, MenuItem, Button, Alert, Pagination, TablePagination, Box } from '@mui/material';
import Header from '../components/Header';
import { Container } from '@mui/system';
import settings from '../config/Settings';
import { Navigate, useParams } from 'react-router-dom';

import { Info, Email, MeetingRoom, WorkspacePremium, Business, EmojiEvents, ConstructionOutlined} from '@mui/icons-material';


import AlumniDetails from '../components/AlumniDetails';
import Emails from '../components/Email';
import Halls from '../components/Halls';
import Degrees from '../components/Degrees';
import Companies from '../components/Companies';
import Awards from '../components/Awards';
import Daa from '../components/Daa';
import Yaaa from '../components/Yaaa';
import LifeFellow from '../components/LifeFellow';
import LifeTta from '../components/LifeTta';

export class EditAlumni extends Component {
    constructor(props) {
        super(props)

        this.state = {
            redirect: false,
            redirectTo: '',
            module: 'AlumniDetails',
        }
    }


    modules = {
        AlumniDetails: <AlumniDetails almid={this.props.params.almid}/>,
        Emails: <Emails />,
        Halls: <Halls />,
        Degrees: <Degrees />,
        Companies: <Companies />,
        Awards: <Awards />,
        Daa: <Daa />,
        Yaaa: <Yaaa />,
        LifeFellow: <LifeFellow />,
        LifeTta: <LifeTta />,
    };

    renderModules = (moduleName) => {
        return this.modules[moduleName];
    }

    changeModule = (moduleName) => {
        console.log('Changing module to '+moduleName)
        this.setState({ module: moduleName });
    }

    componentDidMount() {
        const {almid} = this.props.params;
        console.log(almid)
    }

    

    render() {
        
        return (
            <div style={{ backgroundColor: grey['200'], minHeight: '100vh' }}>
                {this.state.redirect == true ?
                    <Navigate to={this.state.redirectTo} />
                    : null
                }
                <Header />
                <div  >
                    <Container>
                        <Typography variant="h5" color='secondary' marginTop={2}>Edit Alumni</Typography>
                        <Divider />
                        <Grid container spacing={2}>
                            <Grid item xs={2} spacing={2}>
                                <Stack direction='row' paddingY={2} justifyContent='space-between'>
                                    <Stack direction='column' spacing={2}>
                                        <Button variant="contained" color={this.state.module === 'AlumniDetails' ? 'primary' : 'inherit'} 
                                            startIcon={<Info />} onClick={()=> this.changeModule('AlumniDetails')}>
                                            Alumni Details
                                        </Button>
                                        <Button variant="contained" color={this.state.module === 'Emails' ? 'primary' : 'inherit'} 
                                            startIcon={<Email />} onClick={()=> this.changeModule('Emails')}>
                                            Emails
                                        </Button>
                                        <Button variant="contained" color={this.state.module === 'Halls' ? 'primary' : 'inherit'} 
                                            startIcon={<MeetingRoom />} onClick={()=> this.changeModule('Halls')}>
                                            Halls
                                        </Button>
                                        <Button variant="contained" color={this.state.module === 'Degrees' ? 'primary' : 'inherit'} 
                                            startIcon={<WorkspacePremium />} onClick={()=> this.changeModule('Degrees')}>
                                            Degree
                                        </Button>
                                        <Button variant="contained" color={this.state.module === 'Companies' ? 'primary' : 'inherit'} 
                                            startIcon={<Business />} onClick={()=> this.changeModule('Companies')}>
                                            Companies
                                        </Button>
                                        <Button variant="contained" color={this.state.module === 'Awards' ? 'primary' : 'inherit'} 
                                            startIcon={<EmojiEvents />} onClick={()=> this.changeModule('Awards')}>
                                            Awards
                                        </Button>
                                        <Button variant="contained" color={this.state.module === 'Daa' ? 'primary' : 'inherit'} 
                                            startIcon={<EmojiEvents />} onClick={()=> this.changeModule('Daa')}>
                                            DAA
                                        </Button>
                                        <Button variant="contained" color={this.state.module === 'Yaaa' ? 'primary' : 'inherit'} 
                                            startIcon={<EmojiEvents />} onClick={()=> this.changeModule('Yaaa')}>
                                            YAAA
                                        </Button>
                                        <Button variant="contained" color={this.state.module === 'LifeFellow' ? 'primary' : 'inherit'} 
                                            startIcon={<EmojiEvents />} onClick={()=> this.changeModule('LifeFellow')}>
                                            Life Fellow
                                        </Button>
                                        <Button variant="contained" color={this.state.module === 'LifeTta' ? 'primary' : 'inherit'} 
                                            startIcon={<EmojiEvents />} onClick={()=> this.changeModule('LifeTta')}>
                                            Life TTA
                                        </Button>
                                    </Stack>
                                    <Divider orientation='vertical' flexItem />
                                </Stack>
                            </Grid>
                            <Grid item xs={10}>
                                <Box paddingY={2}>
                                    <Paper sx={{ padding: 4, height: 'auto', paddingY: 2 }} flexItem>
                                        {this.renderModules(this.state.module)}
                                    </Paper>
                                </Box>
                            </Grid>
                        </Grid>

                    </Container>
                </div>
            </div>
        )
    }
}

export default (props) => (
    <EditAlumni
        {...props}
        params={useParams()}
    />
)