import { Button, Chip, Grid, Stack, Typography } from "@mui/material";
import React, { Component, Fragment } from "react";
import Moment from "react-moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import parser from "html-react-parser";
import { grey } from "@mui/material/colors";
import { Link } from "react-router-dom";

export class Email extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  render() {
    return (
      <Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Message Content</DialogTitle>
          <DialogContent dividers={true}>
            {parser(this.props.message)}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <Grid container>
          <Grid item xs={2}>
            <Typography variant="body2">
              <Moment format="DD-MM-YYYY hh:mm a">
                {this.props.timestamp}
              </Moment>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link
              to={`/email-details/${this.props.dispatchId}`}       
              style={{ textDecoration: "none" }}
            >
              <Typography variant="body2">{this.props.subject}</Typography>
            </Link>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={this.handleClickOpen}>View Message</Button>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">
              {this.props.total === this.props.sent ? (
                <Chip label="Completed" color="success" />
              ) : (
                <Stack direction={"row"}>
                  <Typography variant="body2" paddingX={1}>
                    Sent: {this.props.sent}/{this.props.total},
                  </Typography>
                  <Typography variant="body2" style={{ color: "red" }}>
                    Failed:{this.props.failed}
                  </Typography>
                </Stack>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default Email;
