import React, { Component } from 'react'

export class Degrees extends Component {
  render() {
    return (
      <div>Degrees</div>
    )
  }
}

export default Degrees