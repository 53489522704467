import { Box } from '@mui/system'
import React, { Component } from 'react'
import { grey } from '@mui/material/colors';
import { Typography, Stack, Button, IconButton, Chip } from '@mui/material';

import settings from '../config/Settings';
import axios from 'axios';
import { connect } from 'react-redux';
import { setJWT, setName } from '../redux/actions/actions';

import AttachFileIcon from '@mui/icons-material/AttachFile';


export class EmailAttachment extends Component {
    constructor(props) {
        super(props)

        this.state = {
            files: []
        }
    }


    handleCapture = ({ target }) => {
        const formData = new FormData();
        formData.append('FUNAME', target.files[0]);
        formData.append('FUDESC', target.files[0].name);

        axios.post(settings.serverUrl + "uploadFile", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.props.jwtToken}`
            }
        }).then(res => {
            if (res.data.status == 'success') {
                let f = this.state.files;
                console.log(res);
                f.push({
                    id: res.data.data[0].FU_ID,
                    name: target.files[0].name
                });
                this.setState({ files: f }, () => {
                    console.log(this.state.files);
                    if (this.props.attachmentChanged !== undefined) {
                        // prepare attachment list
                        let attachments = '';
                        this.state.files.map(f => {
                            attachments += f.id + ',';
                        })
                        attachments = attachments.slice(0, -1);
                        this.props.attachmentChanged(attachments);
                    }
                });
            } else {
                console.log(res.data.message);
            }
        });
    };

    handleDelete = (id) => {
        axios.post(settings.serverUrl + "uploadsDelete", {
            'fuid': id
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.props.jwtToken}`
            }
        }).then(res => {
            if (res.data.status == 'success') {
                let f = this.state.files;
                let findex = f.findIndex(x=> x.id == id);
                // console.log('index', findex);
                f.splice(findex, 1);
                this.setState({ files: f }, () => {
                    if (this.props.attachmentChanged !== undefined) {
                        // prepare attachment list
                        let attachments = '';
                        this.state.files.map(f => {
                            attachments += f.id + ',';
                        })
                        attachments = attachments.slice(0, -1);
                        this.props.attachmentChanged(attachments);
                    }
                });
            } else {
                console.log(res.data.message);
            }
        });

    }

    render() {
        const { files } = this.state;
        return (
            <Box sx={{ backgroundColor: grey[100], padding: 2 }}>
                <Stack direction='row' spacing={2}>
                    <input
                        accept="image/*"
                        id="upload-file"
                        onChange={this.handleCapture}
                        type="file"
                        style={{ display: 'none', }}
                    />
                    <Typography variant="subtitle1">Attachments: </Typography>
                    <label htmlFor="upload-file">
                        <IconButton color="primary" component="span">
                            <AttachFileIcon />
                        </IconButton>
                    </label>
                    {
                        files.length !== 0 ?
                            files.map((f, index) => {
                                return (
                                    <Chip key={f.id} label={f.name} onDelete={() => this.handleDelete(f.id)} />
                                )
                            })
                            : null
                    }
                </Stack>

            </Box >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        name: state.name,
        jwtToken: state.jwtToken,
    }
}

const mapDispatchToProps = {
    setJWT, setName
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailAttachment)