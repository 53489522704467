import { Alert, Button, Divider, Grid, Link, Paper, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system';
import React, { Component } from 'react';
import axios from 'axios';
import settings from '../config/Settings';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setJWT, setName } from '../redux/actions/actions';

export class Login extends Component {
	constructor(props) {
		super(props)

		this.state = {
			username: '',
			password: '',
			isValid: true,
			message: '',
			redirectToDashboard: false
		}
	}


	handleUsernameChange = event => {
		this.setState({ username: event.target.value });
	}
	handlePasswordChange = event => {
		this.setState({ password: event.target.value });
	}

	checkLogin = () => {
        if(this.props.jwtToken == ''){
            this.setState({ redirectTo: '/' });
            this.setState({ redirect: true });
        }
    }

	componentDidMount(){
        this.checkLogin();
    }

    componentDidUpdate(prevProps){
        if(this.props.jwtToken != prevProps.jwtToken){
			this.checkLogin();
		}
		
    }

	handleSubmit = () => {
		console.log('submit');
		this.setState({ message: "", isValid: true });
		if (this.state.username.trim() == '') {
			this.setState({ message: "Enter Username", isValid: false });
			return;
		}
		if (this.state.password.trim() == '') {
			this.setState({ message: "Enter Password", isValid: false });
			return;
		}
		axios.post(settings.serverUrl + "authenticate", {
			uid: this.state.username,
			psw: this.state.password
		}).then(res => {
			if (res.data.status == 'success') {
				console.log('Authentication successful');
				this.props.setJWT(res.data.jwtToken);
				this.props.setName(res.data.username);
				this.setState({redirectToDashboard: true});
			} else {
				console.log(res.data.message);
			}
		}).catch(err => {
			console.log("Error:", err);
			this.setState({message: 'Unable to Connect to the Server.'})
			this.setState({isValid: false});
		})
	}

	render() {
		return (
			<Container>
				{
					this.state.redirectToDashboard == true ? <Navigate to="/dashboard" /> :  null
	}
				<Paper elevation={8} style={{ padding: 20, width: '400px', height: '400px', margin: '50px auto', justifyContent: 'center' }}>
					<Grid container direction={'column'} spacing={2}>
						<Grid item>
							<Grid container spacing={4}>
								<Grid item><img src={require('../assets/images/IITKGP.png')} width={80} /></Grid>
								<Grid item>
									<Typography variant='h4'>Alumni Office</Typography>
									<Typography variant="h5">IIT Kharagpur</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item><Divider /></Grid>
						<Grid item>
							<TextField
								variant="filled"
								autoFocus={true}
								fullWidth label='Username'
								value={this.state.username}
								onChange={this.handleUsernameChange}
							></TextField>
						</Grid>
						<Grid item>
							<TextField
								variant="filled"
								fullWidth label='Password'
								type='password'
								value={this.state.password}
								onChange={this.handlePasswordChange}
							></TextField>
						</Grid>
						<Grid item>
							<Button fullWidth type='submit' variant='contained' onClick={this.handleSubmit}>Submit</Button>
						</Grid>
						<Grid item>
							<Link color='primary'>Forgot Password</Link>
						</Grid>
						<Grid item>
							{
								!this.state.isValid ?
									<Alert severity="warning">{this.state.message}</Alert>
									: null
							}
						</Grid>
					</Grid>
				</Paper>
			</Container>
		)
	}
}

const mapStateToProps = (state) => {
    return {
        name: state.name,
        jwtToken: state.jwtToken,
    }
}

const mapDispatchToProps = {
    setJWT, setName	
}


export default connect(mapStateToProps, mapDispatchToProps)(Login);