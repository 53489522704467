import { Stack, TextField, Button } from '@mui/material';
import axios from 'axios'
import React, { Component } from 'react'
import settings from '../config/Settings';
import { Navigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { connect } from 'react-redux';
import { setJWT, setName } from '../redux/actions/actions';

export class MailSettings extends Component {
	constructor(props) {
		super(props)

		this.state = {
			redirect: false,
            redirectTo: '',
			mailserver: '',
			mailserverport: '',
			senderid: '',
			senderpsw: '',
			openInfoDialog: false,
            infoMessage: '',
		}
	}

	handleFormDataChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	getEmailSettings = () => {
		axios.post(settings.serverUrl + "getConfigByCatg", {
			cfgcatg: 'M'
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.props.jwtToken}`
			}
		}).then(res => {
			if (res.data.status == 'success') {
				console.log('Received Email Settings');

				res.data.data.map(s => {
					this.setState({ [s.KEYF]: atob(s.KYVALUE) });
				})
			} else {
				console.log(res.data.message);
				if (res.data.status == 'error') {
					this.setState({ redirectTo: '/' });
					this.setState({ redirect: true });
				}
			}
		}).catch(err => {
			console.log("Error:", err);
		})

	}

	updateMailSettings = (key) => {
		console.log(this.state[key]);
		axios.post(settings.serverUrl + "updateConfig", {
			"keyfld": key,
			"keyvalue": btoa(this.state[key]),
			"keytype": 'M'
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.props.jwtToken}`
			}
		}).then(res => {
			if (res.data.status == 'success') {
				this.setState({ infoMessage: 'Data Updated Successfully' });
                this.setState({ openInfoDialog: true });
			} else {
				console.log(res.data.message);
				if (res.data.status == 'error') {
					this.setState({ redirectTo: '/' });
					this.setState({ redirect: true });
				}
			}
		}).catch(err => {
			console.log("Error:", err);
		})
	}


	componentDidMount() {
		this.getEmailSettings();
	}


	handleDialogClose = (event, reason) => {
        if (reason && reason == "backdropClick" && "escapeKeyDown")
            return;
        else {
            this.setState({ openDialog: false });
            this.setState({ openInfoDialog: false });
        }
    };

	render() {
		return (
			<Stack direction='column' spacing={2}>
				{this.state.redirect == true ?
                    <Navigate to={this.state.redirectTo} />
                    : null
                }
				<Stack direction='row' spacing={2}>
					<TextField
						variant="filled"
						name='mailserver'
						fullWidth label='Mail Server'
						value={this.state.mailserver}
						onChange={this.handleFormDataChange}
					></TextField>
					<Button onClick={()=>this.updateMailSettings('mailserver')} type='submit' variant='contained'>Update</Button>
				</Stack>

				<Stack direction='row' spacing={2}>
					<TextField
						variant="filled"
						name='mailserverport'
						fullWidth label='Mail Server Port'
						value={this.state.mailserverport}
						onChange={this.handleFormDataChange}
					></TextField>
					<Button onClick={()=>this.updateMailSettings('mailserverport')} type='submit' variant='contained'>Update</Button>
				</Stack>
				<Stack direction='row' spacing={2}>
					<TextField
						variant="filled"
						name='senderid'
						fullWidth label='Sender Email ID'
						value={this.state.senderid}
						onChange={this.handleFormDataChange}
					></TextField>
					<Button onClick={()=>this.updateMailSettings('senderid')} type='submit' variant='contained'>Update</Button>
				</Stack>
				<Stack direction='row' spacing={2}>
					<TextField
						variant="filled"
						name='senderpsw'
						fullWidth label='Sender Password'
						type='password'
						value={this.state.senderpsw}
						onChange={this.handleFormDataChange}
					></TextField>
					<Button onClick={()=>this.updateMailSettings('senderpsw')} type='submit' variant='contained'>Update</Button>
				</Stack>
				<Dialog
                    open={this.state.openInfoDialog}
                    onClose={this.handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Information</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.infoMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
			</Stack>
		)
	}
}


const mapStateToProps = (state) => {
	return {
		name: state.name,
		jwtToken: state.jwtToken,
	}
}

const mapDispatchToProps = {
	setJWT, setName
}

export default connect(mapStateToProps, mapDispatchToProps)(MailSettings);