import React, { Component } from 'react'

export class Companies extends Component {
  render() {
    return (
      <div>Companies</div>
    )
  }
}

export default Companies