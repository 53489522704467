import React, { Component } from 'react'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { Button, FormControl, InputLabel, MenuItem, Select, Typography, IconButton, Grid, Stack } from '@mui/material';
import ConfirmationDialog from './ConfirmationDialog';


import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export class FilterItem extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filters: {},
            selectedFilter: '',
            selectedFilterFullName: '',
            selectedOptions: [],
        }
    }

    changeFilter = (event) => {
        this.setState({ selectedFilter: event.target.value });
        let filter = this.props.filterFields.find(x => x.name == event.target.value);
        this.setState({ selectedFilterFullName: filter.fullname })
        this.setState({ selectedOptions: [] })
        if (this.props.onFieldChange !== undefined) {
            this.props.onFieldChange(this.props.index, event.target.value);
        }
    }

    handleFilterChange = (event) => {
        if (!this.props.lastItem) {
            confirmAlert({
                title: 'Warning',
                message: 'Changing this filter will lead to remove all the filter(s) below this filter. Do you want to continue ?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.changeFilter(event);
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {

                        }
                    }
                ]
            });
        } else {
            this.changeFilter(event);
        }
    }

    changeFieldOption = (event, newValue) => {
        this.setState({ selectedOptions: newValue });
        if (this.props.onFieldOptionChange !== undefined) {
            this.props.onFieldOptionChange(this.props.index, newValue);
        }
    }

    handleFieldOptionChanged = (event, newValue) => {
        if (!this.props.lastItem) {
            confirmAlert({
                title: 'Warning',
                message: 'Changing this filter option will lead to remove all the filter(s) below this filter. Do you want to continue ?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.changeFieldOption(event, newValue);
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {

                        }
                    }
                ]
            });
        } else {
            this.changeFieldOption(event, newValue);
        }
    }

    removeFilter = () => {
        confirmAlert({
            title: 'Warning',
            message: 'Removing this filter will lead to remove all the filter(s) below this filter. Do you want to continue ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (this.props.onRemove !== undefined) {
                            this.props.onRemove(this.props.index);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        });
    }

    handleRemoveConfirm = () => {
        this.setState({ dialogOpen: true });
    }

    render() {
        const { filterFields } = this.props;
        return (
            <React.Fragment>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{this.state.selectedFilter == '' ? 'Select Filter' : this.state.selectedFilterFullName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Field</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.selectedFilter}
                                label="Select Field"
                                onChange={this.handleFilterChange}
                            >
                                {
                                    filterFields.map((ff, i) => {
                                        return (
                                            <MenuItem key={i} value={ff.name}>{ff.fullname}</MenuItem>
                                        )

                                    })
                                }
                            </Select>
                        </FormControl>
                        {
                            this.props.filterOptions.length > 0 ?
                                <Autocomplete
                                    multiple
                                    id="fixed-tags-demo"
                                    value={this.state.selectedOptions}
                                    onChange={this.handleFieldOptionChanged}
                                    options={this.props.filterOptions}
                                    getOptionLabel={(option) => `${option.NAME} (${option.CNT})`}
                                    style={{ 'margin_top': '10px' }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Selection Options" placeholder="Type here to search" style={{'margin-top': '10px'}} />
                                    )}
                                />
                                : null
                        }
                        <Grid container justifyContent='center' paddingTop={2}>
                            <Button marginTop={2} variant='contained' onClick={this.removeFilter} color='error' startIcon={<DeleteIcon />}>
                                Remove This Filter
                            </Button>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </React.Fragment>
        )
    }
}

export default FilterItem