import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import Header from "../components/Header";
import { grey } from "@mui/material/colors";
import settings from "../config/Settings";
import axios from "axios";
import { connect } from "react-redux";
import { setJWT, setName } from "../redux/actions/actions";
import Moment from "react-moment";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import SearchIcon from "@mui/icons-material/Search";
import BackspaceIcon from "@mui/icons-material/Backspace";
import validator from "validator";

import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TablePagination,
  Typography,
  Button,
  Alert,
  TextField,
} from "@mui/material";

class BirthdayList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      redirectTo: "",
      birthdayList: [],
      draw: 1,
      recordsTotal: 0,
      recordsFiltered: 0,
      recordsPerPage: 10,
      currentPage: 0,
      searchString: "",
      loadingBirthdayList: true,
      openAddDialog: false,
      openEditDialog: false,
      openInfoDialog: false,
      message: "",
      infoMessage: "",
      isValid: true,
      slno: 0,
      name: "",
      dob: moment().format("YYYY-MM-DD"),
      email: "",
      remarks: "",
    };
  }

  loadBirthdayList = () => {
    this.setState({ birthdayList: [] });
    this.setState({ loadBirthdayList: true });
    axios
      .post(
        settings.serverUrl + "getBirthdayList",
        {
          draw: this.state.draw,
          start: this.state.currentPage * this.state.recordsPerPage,
          length: this.state.recordsPerPage,
          search: this.state.searchString,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.jwtToken}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          //   console.log('Email logs received');
          //   console.log(res.data.data);
          this.setState({
            recordsTotal: res.data.recordsTotal,
            recordsFiltered: res.data.recordsFiltered,
          });
          this.setState({ birthdayList: res.data.data });
          this.setState({ loadingBirthdayList: false });
        } else {
          console.log(res.data.message);
          if (res.data.status === "error") {
            this.setState({ redirectTo: "/" });
            this.setState({ redirect: true });
          }
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      });
    this.setState((prevState) => {
      return { draw: prevState.draw + 1 };
    });
  };

  clearSearch = () => {
    this.setState({ searchString: "" }, () => {
      this.loadBirthdayList();
    });
  };

  searchWithin = () => {
    this.loadBirthdayList();
  };

  handleSearchTextChange = (event) => {
    //console.log(event.target.value);
    this.setState({ searchString: event.target.value });
  };

  handlePageChange = (event, value) => {
    this.setState({ currentPage: value });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ recordsPerPage: parseInt(event.target.value, 10) }, () => {
      this.loadEmailLogs();
      this.setState({ currentPage: 0 });
    });
  };

  showAddDialog = () => {
    this.setState({ message: "", isValid: true });
    this.setState({ name: '', dob: moment().format("YYYY-MM-DD"), email: '', remarks: ''})
    this.setState({ openAddDialog: true });
  };

  closeBackdrop = () => {
    this.setState({ openBackdrop: false });
  };

  handleDialogClose = (event, reason) => {
    if (reason && reason == "backdropClick" && "escapeKeyDown") return;
    else {
      this.setState({ openAddDialog: false });
    }
  };

  handleEditDialogClose = (event, reason) => {
    if (reason && reason == "backdropClick" && "escapeKeyDown") return;
    else {
      this.setState({ openEditDialog: false });
    }
  };

  handleInfoDialogClose = (event, reason) => {
    if (reason && reason == "backdropClick" && "escapeKeyDown") return;
    else {
      this.setState({ openInfoDialog: false });
    }
  };

  handleInfoCancel = (event, reason) => {
    if (reason && reason == "backdropClick" && "escapeKeyDown") return;
    else {
      this.setState({ openInfoDialog: false });
      this.setState({ infoReturn: false });
    }
  };

  handleFormDataChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addToBirthdayList = () => {
    this.setState({ message: "", isValid: true });
    if (this.state.name.trim() == "") {
      this.setState({ message: "Name is required", isValid: false });
      return;
    }
    if (!moment(this.state.dob).isValid()) {
      this.setState({ message: "Invalid date format", isValid: false });
      return;
    }
    if (!validator.isEmail(this.state.email)) {
      this.setState({ message: "Invalid email address", isValid: false });
      return;
    }
    this.setState({ openAddDialog: false });
    axios
      .post(
        settings.serverUrl + "save2bdMailList",
        {
          alm_name: this.state.name,
          alm_dob: this.state.dob,
          alm_email: this.state.email,
          remarks: this.state.remarks,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.jwtToken}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          //   console.log("Successfully added to birthday list");
          this.setState({
            infoMessage: "Successfully added to the Birthday List",
          });
          this.setState({ openInfoDialog: true });
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      });
    this.loadBirthdayList();
  };

  editItem = (slno) => {
    var item = this.state.birthdayList.find((i) => i.SLNO === slno);
    if (item !== undefined) {
      var d = moment(item.ALM_DOB, moment.ISO_8601).format("YYYY-MM-DD");
      this.setState({
        slno: item.SLNO,
        name: item.ALM_NAME,
        email: item.ALM_EMAIL,
        dob: d,
        remarks: item.REMARKS,
      });
      this.setState({ openEditDialog: true });
    } else {
      window.alert("Invalid sl no");
    }
  };

  editBirthdayList = () => {
    this.setState({ message: "", isValid: true });
    if (this.state.name.trim() == "") {
      this.setState({ message: "Name is required", isValid: false });
      return;
    }
    if (!moment(this.state.dob).isValid()) {
      this.setState({ message: "Invalid date format", isValid: false });
      return;
    }
    if (!validator.isEmail(this.state.email)) {
      this.setState({ message: "Invalid email address", isValid: false });
      return;
    }
    this.setState({ openEditDialog: false });
    axios
      .post(
        settings.serverUrl + "update2bdMailList",
        {
          slno: this.state.slno,
          alm_name: this.state.name,
          alm_dob: this.state.dob,
          alm_email: this.state.email,
          remarks: this.state.remarks,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.jwtToken}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          //   console.log("Successfully added to birthday list");
          this.setState({
            infoMessage: "Successfully updated to the Birthday List",
          });
          this.setState({ openInfoDialog: true });
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      });
    this.loadBirthdayList();
  };

  deleteFromList = (slno) => {
    if (window.confirm("Do you really want to delete this data?")) {
      axios
        .post(
          settings.serverUrl + "delFrombdMailList",
          {
            slno: slno,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.jwtToken}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            //   console.log("Successfully added to birthday list");
            this.setState({
              infoMessage: "Successfully deleted from the Birthday List",
            });
            this.setState({ openInfoDialog: true });
            this.loadBirthdayList();
          }
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.loadEmailLogs();
    }
  }
  componentDidMount() {
    if (this.props.jwtToken === "") {
      this.setState({ redirectTo: "/" });
      this.setState({ redirect: true });
    }
    // this.loadEmailLogs();
    this.loadBirthdayList();
  }

  render() {
    return (
      <div style={{ backgroundColor: grey["200"], minHeight: "100vh" }}>
        {this.state.redirect === true ? (
          <Navigate to={this.state.redirectTo} />
        ) : null}
        <Header />
        <div>
          <Container style={{ marginTop: 20 }}>
            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={4}>
                <Typography variant="h5" color="secondary" marginTop={2}>
                  Birthday List
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Button
                  marginTop={2}
                  variant="contained"
                  onClick={this.showAddDialog}
                >
                  Add to the list
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Name, Email or DOB"
                    inputProps={{ "aria-label": "Search Name, Email or DOB" }}
                    onChange={this.handleSearchTextChange}
                    value={this.state.searchString}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={this.clearSearch}
                  >
                    <BackspaceIcon />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                    onClick={this.searchWithin}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Grid>
            </Grid>
            <Divider />
            <Paper style={{ padding: 15, marginTop: 10 }}>
              <Stack spacing={2} style={{ marginBottom: 20 }}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">Name</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Date of Birth</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2">Email</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Remarks</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="subtitle2">#</Typography>
                  </Grid>
                </Grid>
                <Divider />
                {this.state.loadingBirthdayList ? <CircularProgress /> : null}
                {this.state.birthdayList.map((bl) => {
                  return (
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body2" paddingX={1}>
                          {bl.ALM_NAME}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2" paddingX={1}>
                          <Moment format="DD-MM-YYYY">{bl.ALM_DOB}</Moment>
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2" paddingX={1}>
                          {bl.ALM_EMAIL}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2" paddingX={1}>
                          {bl.REMARKS}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          type="button"
                          size="small"
                          color="primary"
                          onClick={() => this.editItem(bl.SLNO)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          type="button"
                          size="small"
                          color="error"
                          onClick={() => this.deleteFromList(bl.SLNO)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })}
                <TablePagination
                  component="div"
                  count={this.state.recordsFiltered}
                  page={this.state.currentPage}
                  onPageChange={this.handlePageChange}
                  rowsPerPage={this.state.recordsPerPage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  showFirstButton={true}
                  showLastButton={true}
                />
              </Stack>
            </Paper>
          </Container>
        </div>

        <Dialog
          open={this.state.openAddDialog}
          onClose={this.handleDialogClose}
          maxWidth="sx"
          scroll={"paper"}
          PaperProps={{
            sx: {
              width: "50%",
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="alert-dialog-title">
            Add to Birthday List
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText id="alert-dialog-description">
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  {!this.state.isValid ? (
                    <Alert severity="warning">{this.state.message}</Alert>
                  ) : null}
                </Grid>
                <Grid item>
                  <Stack spacing={2}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Name"
                      name="name"
                      value={this.state.name}
                      size="small"
                      onChange={this.handleFormDataChange}
                    ></TextField>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Date of Birth"
                      name="dob"
                      value={this.state.dob}
                      type="date"
                      size="small"
                      onChange={this.handleFormDataChange}
                    ></TextField>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      value={this.state.email}
                      size="small"
                      onChange={this.handleFormDataChange}
                    ></TextField>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Remarks"
                      name="remarks"
                      value={this.state.remarks}
                      multiline
                      rows={4}
                      size="small"
                      onChange={this.handleFormDataChange}
                    ></TextField>
                  </Stack>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={this.addToBirthdayList}
            >
              Submit
            </Button>
            <Button onClick={this.handleDialogClose}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openEditDialog}
          onClose={this.handleDialogClose}
          maxWidth="sx"
          scroll={"paper"}
          PaperProps={{
            sx: {
              width: "50%",
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="alert-dialog-title">
            Edit item in Birthday List
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText id="alert-dialog-description">
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  {!this.state.isValid ? (
                    <Alert severity="warning">{this.state.message}</Alert>
                  ) : null}
                </Grid>
                <Grid item>
                  <Stack spacing={2}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Name"
                      name="name"
                      value={this.state.name}
                      size="small"
                      onChange={this.handleFormDataChange}
                    ></TextField>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Date of Birth"
                      name="dob"
                      value={this.state.dob}
                      type="date"
                      size="small"
                      onChange={this.handleFormDataChange}
                    ></TextField>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      value={this.state.email}
                      size="small"
                      onChange={this.handleFormDataChange}
                    ></TextField>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Remarks"
                      name="remarks"
                      value={this.state.remarks}
                      multiline
                      rows={4}
                      size="small"
                      onChange={this.handleFormDataChange}
                    ></TextField>
                  </Stack>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={this.editBirthdayList}
            >
              Submit
            </Button>
            <Button onClick={this.handleEditDialogClose}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openInfoDialog}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Information</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.infoMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleInfoDialogClose}>OK</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.name,
    jwtToken: state.jwtToken,
  };
};

const mapDispatchToProps = {
  setJWT,
  setName,
};

export default connect(mapStateToProps, mapDispatchToProps)(BirthdayList);
